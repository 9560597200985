import React from 'react';
import {Box} from '@mui/system';
import {Typography, Stack, SxProps, Theme} from '@mui/material';

import {AppData} from '../data/AppsData';

import ImgComponent from './ImgComponent';
import PageFooter from './PageFooter';
import {AppleAppStoreButton, GooglePlayStoreButton} from './AppStoreButtons';
import AppScreenshotsGallery from './AppScreenshotsGallery';
import { SxObject, SxPropsParam } from 'tsui/Mui/SxPropsUtil';

const screenShotRatio = 1024 / 600;

const contentsWidth = 800;
const contentsHeight = 600;

const padding = 10;
const galleryHeight = 160;

const mainSectionHeight = contentsHeight - padding * 3 - galleryHeight;


export default function AppHomePage(props: {app: AppData}) {
    const app = props.app;

    return (
        <Box
            sx={{
                padding: 0,
                margin: 0,

                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                // backgroundImage: `url('${app.pageBackgroundImage}')`,
                backgroundColor: app.background.color ? app.background.color : undefined,
                backgroundImage: app.background.mobile ? `url('${app.background.image}')` : undefined,

                backgroundAttachment: 'fixed',
                backgroundClip: 'content-box',
            }}
        >
            <Stack
                direction='column'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                    height: '100%',
                }}
            >
                <ImgComponent
                    src={app.titleImage}
                    alt={app.titleImage}
                    sx={{
                        mt: 2,
                        // width: 600,
                        height: 200,
                    }}
                />


                <PageBody app={app} />

                <Box
                    sx={{
                        //height: 100,
                        flex: 1,
                    }}
                ></Box>
            </Stack>
            <PageFooter />
        </Box>
    );
}


function PageBody(props: {app: AppData}) {
    const [currentScreenshotIndex, setCurrentScreenshotIndex] = React.useState(0);
    const app = props.app;

    //console.log(app);

    return (
        <Stack
            direction='column'
            sx={{
                position: 'relative',
                width: contentsWidth,
                height: contentsHeight,

                backgroundColor: 'background.paper',
                borderRadius: 4,
                boxShadow: 10,
            }}
        >
            <Stack direction='row' sx={{
                mb: 1,
                p: 1,
            }}>
                <MainScreenshot app={app} screenshotIndex={currentScreenshotIndex} sx={{
                    // ml: 1,
                    // mt: 1,
                    // m: 1,
                    height: mainSectionHeight,
                }}/>

                <Stack
                    direction='column'
                    alignItems='center'
                    justifyContent='space-between'
                    sx={{
                        left: padding * 2 + mainSectionHeight / screenShotRatio,
                        right: padding,
                        top: padding,
                        height: mainSectionHeight,
                        mx: 4,
                    }}
                >
                    <Typography variant='body1' align='center' sx={{mt: 1}}>
                        {app.description}
                    </Typography>

                    <Stack direction='column' alignItems='center' spacing={1}>
                        <ImgComponent src={app.qr} alt='qr' sx={{width: 120}} />
                        <Typography variant='body1'>Scan the QR code with your phone to download the game.</Typography>
                    </Stack>

                    <Stack direction='row'>
                        <GooglePlayStoreButton href={app.downloadLink} />
                        <AppleAppStoreButton href={app.downloadLink} />
                    </Stack>

                </Stack>
            </Stack>

            <Box
                    sx={{
                        //height: 100,
                        flex: 1,
                    }}
                ></Box>

            <AppScreenshotsGallery
                    app={app}
                    onThumbnailClick={(index) => setCurrentScreenshotIndex(index)}
                    sx={{
                        //flex: 1,
                        height: galleryHeight,
                        mx: 1,
                        mb: 1,
                    }}
            />
        </Stack>
    );
}


interface MainScreenshotProps {
    app: AppData;
    screenshotIndex: number;
    sx?: SxObject;
    //sx: SxProps<Theme>;
};

// const galleryBoxShadow = 'inset 1px 1px 5px 1px #000000';
const galleryBoxShadow = 'inset 1px 1px 5px 1px #ffffffff';
const galleryBackgroundColor = '#2f2f2f';


function MainScreenshot(props: MainScreenshotProps) {
    const app = props.app;

    if (app.screenshots.length === 0)
        return <></>;

    let screenshotIndex = props.screenshotIndex;
    
    if (screenshotIndex >= app.screenshots.length)
        screenshotIndex = app.screenshots.length - 1;

    return <Stack direction='column'
        sx={[{...props.sx}, {}]}

    // sx={[{...props.sx}, {
        // ml: 1,
        // mt: 1,
        // p: 2,
        // p: 1,
        //height: mainSectionHeight,
    >
        <ImgComponent
            src={app.screenshots[screenshotIndex].image}
            alt={app.screenshots[screenshotIndex].image}
            sx={{
                // left: padding,
                // top: padding,
                // height: mainSectionHeight,
                //m: 2,
                // m: 2,
                height: '100%',

                boxShadow: galleryBoxShadow,
                background: galleryBackgroundColor,
                borderRadius: 2,

                transition: 'opacity 0.8s',
            }}
           
        />
    </Stack>

}
