import React from 'react';
import Env from "env";

import AppHomePageMobile from "./AppHomePageMobile";
import AppHomePage from "./AppHomePage";
import { AppData } from 'data/AppsData';

interface Props {
    app: AppData;
    //tag: string;
}

export default function AppPage(props: Props) {

    //const [appData] = React.useState(getAppData(props.tag));


    if (Env.isMobile) 
        return <AppHomePageMobile app={props.app}/>;

    return <AppHomePage app={props.app}/>;
}

