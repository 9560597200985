import React from 'react';
import {Box, Stack} from '@mui/material';

import ImgComponent from './ImgComponent';
import { GalleryItem } from './GalleryItem';
import { AppData } from 'data/AppsData';
import { SxObject } from 'tsui/Mui/SxPropsUtil';


export interface AppScreenshotsGalleryProps {
    app: AppData;
    sx?: SxObject;
    onThumbnailClick: (index: number) => void;
}


export default function AppScreenshotsGallery(props: AppScreenshotsGalleryProps) {
    const app = props.app;

    return (
        <Stack
            direction='row'
            justifyContent='space-between'
            sx={[{...props.sx}, {}]}

            // sx={{
            //     width: '98%',
            //     //height: props.ssHeight,
            //     ml: 1,
            //     mb: 1,
            // }}
        >
            {app.screenshots.map((item, index) => (
                <ImgComponent
                    key={index}
                    src={item.thumbnail}
                    alt={item.thumbnail}
                    loading='lazy'
                    onClick={() => props.onThumbnailClick(index)}
                    sx={{
                        height: '100%',

                        '&:hover': {
                            cursor: 'pointer',
                        },

                        '& img': {},
                    }}
                />
            ))}
        </Stack>
    );
}

