import React from 'react';
import {Container, Box, Grid, Stack, Typography} from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Link from '@mui/material/Link';

import {AppData, AppFaqDataItem} from './data/AppsData';
import ImgComponent from './components/ImgComponent';
import PageFooter from './components/PageFooter';
import Env from './env';
import { pageBackgroundSx, setPageBackground } from 'components/PageBackground';


const contentsWidth = 800;
const contentsHeight = 600;

export default function AppSupportPage(props: {app: AppData}) {

    setPageBackground(props.app);

    if (Env.isMobile) return <AppSupportPageMobile app={props.app} />;

    return <AppSupportPageDesktop app={props.app} />;
}


function AppSupportPageDesktop(props: {app: AppData}) {
    const app = props.app;

    return (
        <Box
            sx={{
                padding: 0,
                margin: 0,

                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,

                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center',
            }}
        >
            <Stack
                direction='column'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                    height: '100%',
                }}
            >
                <ImgComponent
                    src={app.titleImage}
                    sx={{
                        mt: 3,
                        height: 100,
                    }}
                />

                <Box
                    sx={{
                        position: 'absolute',
                        top: 140,
                        bottom: 30,
                        width: contentsWidth,
                        backgroundColor: 'background.paper',
                        borderRadius: 4,
                        boxShadow: 2,
                    }}
                >
                    <FaqBody app={app}/>
                </Box>

                {/* <Box sx={{flex: 1}}/> */}
            </Stack>
            <PageFooter />
        </Box>
    );
}

function AppSupportPageMobile(props: {app: AppData}) {
    const emailLinkRef = React.useRef<HTMLAnchorElement>(null);
    const app = props.app;

    return (
        <Stack
            direction='column'
            alignContent='center'
            justifyContent='center'
            alignItems='center'
        >
            <ImgComponent
                src={app.titleImage}
                sx={{
                    mt: 1,
                    width: '90%',
                }}
            />

            <Box
                sx={{
                    p: 2,
                }}
            >
                <FaqBody app={app}/>
            </Box>
        </Stack>
    );
        // </Container>

}

function FaqBody(props: {app: AppData}) {
    const emailLinkRef = React.useRef<HTMLAnchorElement>(null);
    const app = props.app;

    React.useLayoutEffect(() => {
        if (emailLinkRef.current) {
            let link = emailLinkRef.current;
            let href = 'mailto:';
            let base64 = 'c3VwcG9ydEBha2F0ZWsuYml6'; // bas64encode.org

            //href += "support@akatek.biz";
            href += atob(base64);
            href += '?subject=' + encodeURIComponent(app.title);
            link.href = href;
        }
    }, []);

    const faq  = app.faqData;

    return (
        <>
            <Typography
                fontSize={22}
                align='center'
                sx={{
                    my: 2,
                }}
            >
                {faq.title}
            </Typography>

            {faq.items.map((item, index) => (
                <FaqItem index={index} item={item} />
            ))}

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>How can I contact you?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>Please don't hesitate to...</Typography>
                    <Link ref={emailLinkRef} href='' target='_new'>
                        Send us an Email
                    </Link>
                </AccordionDetails>
            </Accordion>
        </>
    );
}

interface FaqItemProps {
    index: number;
    item: AppFaqDataItem;
}

function FaqItem(props: FaqItemProps) {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{props.item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{props.item.content}</Typography>
            </AccordionDetails>
        </Accordion>
    );
}
