import React from 'react';

import {Box, Typography, Stack, Link} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import ImgComponent from 'components/ImgComponent';
import { AppData } from 'data/AppsData';


const gameIconHeight = 50;
const gameSectionHeight = 100;


export default function HomePageMobile(props: {apps: Array<AppData>}) {
    return (
        <Stack
            direction='column'
            // alignContent='center'
            // justifyItems='center'
            alignItems='center'
            spacing={2}

            sx={{
                minHeight: '100vh',
                height: '100%',
                // width: '100vw',
            }}
        >
            <ImgComponent
                src='/images/akatek_title2.png'
                sx={{
                    width: '80%',
                }}
            />


            {props.apps.map((app, index) => (
                <AppCard key={index} app={app}/>
            ))}

            {/* <PageFooter /> */}
        </Stack>
    );

                // <PageBody apps={props.apps}/>


}



function AppCard(props: {app: AppData, key: number}) {
    const app = props.app;

    if (app.hidden) {
        return <React.Fragment key={app.tag}></React.Fragment>;
    }

    return (
        <Link key={app.tag} href={app.home} target='_blank' rel='noreferrer' sx={{width: '100%'}}>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                // spacing={2}
                sx={{
                    px: 2,
                    // width: '90%',
                    height: gameSectionHeight,
                    // backgroundColor: '#00000088',
                    backgroundColor: '#88888822',
                    // backgroundColor: 'red',
                    // backgroundColor: 'transparent',
                    shadowOpacity: 0,
                }}
            >
                <ImgComponent src={app.icon} height={gameIconHeight}/>
                <ImgComponent src={app.titleImage} height='80%' />
                <MoreVertIcon htmlColor='gray' />
            </Stack>
        </Link>
    );

    /*
    return (
        <Card
            sx={{
                display: 'flex',
                width: cardWidth,
                height: cardHeigth,
                //maxWidth: 345,
            }}
        >
            <CardActionArea
                href={app.home}
                target='_blank'
                rel='noreferrer'
                sx={{
                    height: '100%',
                }}
            >
                <CardContent
                    sx={
                        {
                            //flex: '1 0 auto',
                        }
                    }
                >
                    <Stack
                        // position='absolute'
                        direction='row'
                        alignContent='center'
                        alignItems='center'
                        justifyContent='center'
                        spacing={3}
                        sx={{
                            height: '100%',
                            m: 2,
                        }}
                    >
                        <ImgComponent src={app.titleImage} width='180' alt={app.title} />
                        <ImgComponent src={app.icon} width={cardWidth / 3} height={cardWidth / 5} alt={app.title} />
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
    */
}
