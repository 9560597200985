import React from 'react';
import {Box, Stack} from '@mui/material';
import {Grid} from '@mui/material';

import ImgComponent from './ImgComponent';
import { AppScreenshotsGalleryProps } from './AppScreenshotsGallery';


const thumbailWidth = '100%';
// const thumbnailHeight = 100;


export default function AppScreenshotsGalleryMobile(props: AppScreenshotsGalleryProps) {
    return (
        <Grid container 
            direction='row'
            spacing={1}
            justifyContent="center"
            alignItems="center"
            sx={[{...props.sx}, {
                // width: '98%',
                // height: props.ssHeight,
                // ml: 1,
                // mb: 1,
                // ml: 2,
            }]}
        >
            {props.app.screenshots.map((item, index) => (
                <Grid key={index} item xs={3}>
                    <ImgComponent
                        // key={index}
                        src={item.mobile}
                        alt={item.thumbnail}
                        loading='lazy'
                        onClick={() => props.onThumbnailClick(index)}
                        sx={{
                            width: thumbailWidth,
                            //height: thumbnailHeight,

                            '& img': {},
                        }}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

