import React from 'react';
import {Container, Box, Grid, Stack, Typography} from '@mui/material';
import Env from 'env';

export default function PageFooter() {
    if (Env.isMobile)
        return (
            <Typography
                fontSize='small'
                align='center'
                sx={{
                    color: 'gray',
                    mt: 5,
                }}
            >
                Copyright © 2021-2024 AkaTek LLC. All Rights Reserved.
            </Typography>
        );

    return (
        <Box
            position='absolute'
            sx={{
                m: 1,
                bottom: 0,
                right: 0,
                left: 0,
                //width: 1,
            }}
        >
            <Typography
                fontSize='small'
                align='center'
                sx={{
                    color: 'gray',
                }}
            >
                Copyright © 2021-2024 AkaTek LLC. All Rights Reserved.
            </Typography>
        </Box>
    );
}
