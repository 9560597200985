import { createTheme } from '@mui/material/styles';

export const galleryBoxShadow = 'inset 1px 1px 5px 1px #000000';
export const galleryBackgroundColor = '#2f2f2f';

const globalFonts = [
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    'sans-serif',
];

const codeFonts = ['source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', 'monospace'];


export const muiTheme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                //paddingNone: true,
                root: {
                    padding: 1,
                }
            }
        },


        MuiTypography: {
            defaultProps: {
                color: '#e1e1e1',
            },
        },        
    },

    palette: {
        mode: 'dark',

        // primary: {
            //main: Colors.red[500],
        // },
    },
});


const globalStyles = {
    // '*': {
    //     margin: 0,
    //     padding: 0,
    // },

    body: {
        margin: 0,
        padding: 0,
        fontFamily: globalFonts.join(','),
        fontSmooth: 'auto',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',

        color: '#2f2f2f',
        backgroundColor: '#000000',
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        // backgroundImage: "url('yaappz/shw_background.jpg')",
        // backgroundAttachment: 'fixed',
        // backgroundClip: 'content-box',

        // color:#2f2f2f;
        // background-color: black ; /*#444444 ; */
        // background-image:url(../images/background.jpg) ;
        // background-repeat: no-repeat;
        // background-size: cover ;
        // background-attachment: fixed ;
        // background-clip: content-box ;
    
        //backgroundColor: theme.pageBackgroundColor,
        //backgroundColor: theme => theme.

        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: "url('/images/brick_background_blue2.png')",
        backgroundAttachment: 'fixed',
        backgroundClip: 'content-box',        

        //minWidth: theme.pageMinWidth,
        //minHeight: theme.pageMinHeight,
        //overflow: 'auto',
    },

    code: {
        fontFamily: codeFonts.join(','),
    },
}



export default globalStyles;

