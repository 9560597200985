import React from 'react';
import {Container, Box, Grid, Stack, Typography} from '@mui/material';
import {Link} from '@mui/material';

import device from 'current-device';

import ImgComponent from './ImgComponent';

const AndroidButtonImg = '/images/google-play-badge.png';
const AppleButtonImg = '/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';

interface Props {
    href: string;
}

export default function AppStoreButton(props: Props) {
    if (device.android()) return <GooglePlayStoreButton {...props} />;

    if (device.ios()) return <AppleAppStoreButton {...props} />;

    return (
        <Stack direction='row'>
            <GooglePlayStoreButton {...props} />
            <AppleAppStoreButton {...props} />
        </Stack>
    );
}

export function GooglePlayStoreButton(props: Props) {
    return (
        <Link href={props.href}>
            <ImgComponent src={AndroidButtonImg} alt='GooglePlay' height={80} />
        </Link>
    );
}

export function AppleAppStoreButton(props: Props) {
    return (
        <Link href={props.href}>
            <ImgComponent
                src={AppleButtonImg}
                alt='App Store'
                height={60}
                sx={{
                    padding: '10px',
                }}
            />
        </Link>
    );
}
