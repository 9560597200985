import {styled} from '@mui/material';
import Img from './Img';
import { SxObject } from 'tsui/Mui/SxPropsUtil';

//const ImgComponent = styled('img')({});

interface ImgComponentProps {
    src: string;
    width?: number | string;
    height?: number | string;
    alt?: string;
    loading?: 'eager' | 'lazy';
    onClick?: () => void;

    sx?: SxObject;
}

export default function ImgComponent(props: ImgComponentProps) {
    return <Img {...props} alt={props.alt ?? props.src}/>;

}

// export default ImgComponent;


