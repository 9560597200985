import { getFileDir, getFileExt, getFileName, makeFilePath } from 'tslib/filename';
import { GalleryItem } from '../components/GalleryItem';


export interface AppFaqDataItem {
    title: string;
    content: string;
}

export interface AppFaqData {
    title: string;
    items: AppFaqDataItem[];
}

export interface AppDataBackground {
    color?: string;
    image?: string;
    mobile?: string;
}


export interface AppSchemaData {
    tag: string;
    title: string;
    home?: string;
    downloadLink?: string;
    qr: string;

    hidden?: boolean;

    shortDescription?: string;
    description?: string;

    titleImage: string;
    cardTitleImage?: string;

    icon: string;
    background: AppDataBackground;

    screenshots: Array<GalleryItem | string>;

    faqData?: AppFaqData;
}

export interface AppData {
    tag: string;
    title: string;
    home: string;
    downloadLink: string;
    qr: string;

    hidden?: boolean;

    shortDescription: string;
    description: string;

    titleImage: string;
    cardTitleImage: string;

    icon: string;
    //pageBackgroundImage: string;
    background: AppDataBackground;

    screenshots: Array<GalleryItem>;
    // screenshotsMobile?: Array<GalleryItem>;

    faqData: AppFaqData;
}

export type AppsData = Array<AppData>;

//export const appsData_ = appDataYaml as AppData;
//export const appsData_ = loadAppsData(appDataYaml as Array<AppSchemaData>);

export function loadAppsData(yamlData: Array<AppSchemaData>) {
    //console.log(yamlData);

    let appsData = new Array<AppData>();

    for (let item of yamlData) {
        //console.log(item);

        let data = {...item} as AppData;

        if (!item.faqData) {
            data.faqData = {
                title: 'FAQ',
                items: [],
            };
        }

        if (!item.home)
            data.home = `apps/${data.tag}`;

        data.titleImage = processImage(item.titleImage);
        data.cardTitleImage = processImage(item.cardTitleImage ?? item.titleImage);


        data.qr = processImage(item.qr);
        data.icon = processImage(item.icon);

        data.screenshots = processScreenshots(item.screenshots);

        // if (item.screenshotsMobile)
        //     data.screenshotsMobile = processScreenshots(item.screenshotsMobile);
        // else {
        //     data.screenshotsMobile = processScreenshots(item.screenshots);
        //     while (data.screenshotsMobile.length > 4)
        //         data.screenshotsMobile.pop();
        // }

        if (!data.background)
            data.background = {};

        if (item.background) {
            if (item.background.image)
                data.background.image = processImage(item.background.image);
            if (item.background.mobile)
                data.background.mobile = processImage(item.background.mobile);
        }

        // data.pageBackgroundImage = processImage(item.pageBackgroundImage);

        appsData.push(data);
    }

    // console.log(appsData);

    return appsData;
}

function processScreenshots(schema?: Array<GalleryItem | string>) {
    let gallery = new Array<GalleryItem>();

    if (!schema || schema.length === 0)
        return gallery;


    for (let sh of schema) {
        if (typeof sh === 'string') {
            let image = processImage(sh, 'screenshots'); 
            gallery.push({
                image: image,
                thumbnail: image,
                mobile: image,
            });
        } else {
            gallery.push({
                image: processImage(sh.image, 'screenshots'),
                thumbnail: processImage(sh.thumbnail ?? sh.image, 'screenshots'),
                mobile: processImage(sh.mobile ?? sh.image, 'screenshots'),
            });
        }
    }

    return gallery;
}

function processImage(image: string | GalleryItem, subdir?: string) {
    let imageFile = typeof image === 'string' ? image : image.image;

    let name = getFileName(imageFile)!;

    let ext = getFileExt(imageFile);
    if (!ext || ext.length === 0)
        name += '.png';

    //console.log(image, ext);

    let dir = getFileDir(imageFile);
    if (!dir) {
        dir = '/render';
        if (subdir)
            dir += '/' + subdir;
    }

    let filePath = makeFilePath(dir, name);

    //console.log(`${image}: -> ${filePath}`);

    return filePath;
}

