import React from 'react';
import {styled} from '@mui/material';
import {Container, Box, Grid, Stack, Typography} from '@mui/material';
import { AppData } from 'data/AppsData';

const developerName = 'AKATEK LLC';
const contactEmail = 'support(at)akatek(dot)biz';


export default function PrivacyPolicy(props: {app: AppData}) {
    return (
        <Box
            sx={{
                position: 'absolute',
                left: 10,
                top: 10,
                right: 10,
                bottom: 10,
                p: 2,
                //maxHeight: '100%',
                overflow: 'auto',
            }}
        >

        <PrivacyPolicyContents app={props.app} />
        </Box>
    );
}

export function PrivacyPolicyContents(props: {app: AppData}) {
    const productName = props.app.title;

    return (<>
            <Title>Privacy Policy</Title>

            <P>
                This privacy policy governs the use of the software application {productName} ("Application") that
                was created by {developerName} ("Developer"). The Application is a computer game.
            </P>

            <Section>What information does the Application obtain and how is it used?</Section>
            <SubSection>User Provided Information</SubSection>
            <P>
                The Application does not collect any information from the user. The results of the completed levels are
                stored locally and are not collected. Those users who opt to use built-in services such as Google Play
                or Apple Game Center will have their stats stored in their accounts.
            </P>

            <SubSection>Automatically Collected Information</SubSection>
            <P>
                The Application may collect certain information automatically for analysing the crashes and bugs. Such
                information may contain stack trace, operating system version and type. No user identifiable information
                is collected.
            </P>

            <Section>Does the Application collect precise real time location information of the device?</Section>
            <P>No. The Application does not collect precise information about the location of your mobile device.</P>

            <Section>Do third parties see and/or have access to information obtained by the Application?</Section>
            <P>No. No information is shared with third parties.</P>

            <P>We may disclose User Provided and Automatically Collected Information:</P>
            <UL>
                <LI>as required by law, such as to comply with a subpoena, or similar legal process;</LI>
                <LI>
                    when we believe in good faith that disclosure is necessary to protect our rights, protect your
                    safety or the safety of others, investigate fraud, or respond to a government request;
                </LI>
                <LI>
                    with our trusted services providers who work on our behalf, do not have an independent use of the
                    information we disclose to them, and have agreed to adhere to the rules set forth in this privacy
                    statement;
                </LI>
                <LI>
                    if Developer is involved in a merger, acquisition, or sale of all or a portion of its assets, you
                    will be notified via email and/or a prominent notice on our Web site of any change in ownership or
                    uses of this information, as well as any choices you may have regarding this information.
                </LI>
                <LI>
                    to advertisers and third party advertising networks and analytics companies as described in the
                    section below
                </LI>
            </UL>

            <Section>Automatic Data Collection and Advertising</Section>
            <P>
                We may work with analytics companies to help us understand how the Application is being used, such as
                the frequency and duration of usage, levels completed or failed, etc.
            </P>
            <P>
                We work with advertisers and third party advertising networks. The links to privacy policy of third
                party service providers used by the app:
            </P>
            <UL>
                <LI>
                    <a href='https://www.google.com/policies/privacy/' target='_blank' rel='noreferrer'>
                        Google Play Services
                    </a>
                </LI>
                <LI>
                    <a href='https://support.google.com/admob/answer/6128543?hl=en' target='_blank' rel='noreferrer'>
                        Google AdMob
                    </a>
                </LI>
            </UL>

            <Section>Data Retention Policy, Managing Your Information</Section>
            <P>
                We will retain User Provided data for as long as you use the Application and for a reasonable time
                thereafter. We will retain Automatically Collected information for up to 24 months and thereafter may
                store it in aggregate. If you would like us to delete User Provided Data that you have provided via the
                Application, please contact us at {contactEmail} and we will respond in a reasonable time.
            </P>

            <Section>Children</Section>
            <P>
                We do not use the Application to knowingly solicit data from or market to children under the age of 13.
                If a parent or guardian becomes aware that his or her child has provided us with information without
                their consent, he or she should contact us at {contactEmail}. We will delete such information from our
                files within a reasonable time.
            </P>

            <Section>Security</Section>
            <P>
                We are concerned about safeguarding the confidentiality of your information. We provide physical,
                electronic, and procedural safeguards to protect information we process and maintain. Please be aware
                that, although we endeavor provide reasonable security for information we process and maintain, no
                security system can prevent all potential security breaches.
            </P>

            <Section>Changes</Section>
            <P>
                This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes
                to our Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as
                continued use is deemed approval of all changes.
            </P>

            <Section>Your Consent</Section>
            <P>
                By using the Application, you are consenting to our processing of your information as set forth in this
                Privacy Policy now and as amended by us.
            </P>

            <Section>Contact us</Section>
            <P>
                If you have any questions regarding privacy while using the Application, or have questions about our
                practices, please contact us via email at {contactEmail}.
            </P>
    </>);
}

const Title = (props: any) => (
    <Typography variant='h3' textAlign='center' sx={{textTransform: 'uppercase', m: 1}}>
        {props.children}
    </Typography>
);
const Section = (props: any) => (
    <Typography variant='h5' sx={{mt: 2}}>
        {props.children}
    </Typography>
);
const SubSection = (props: any) => (
    <Typography variant='subtitle1' sx={{mt: 1, fontWeight: 'bold'}}>
        {props.children}
    </Typography>
);
const P = (props: any) => (
    <Typography variant='body1' sx={{mt: 1}}>
        {props.children}
    </Typography>
);

const UlComponent = styled('ul')({});
const LiComponent = styled('li')({});

const UL = (props: any) => <UlComponent sx={{mt: 1}}>{props.children}</UlComponent>;

const LI = (props: any) => (
    <LiComponent sx={{mt: 1, ml: 4, color: 'white'}}>
        <P>{props.children}</P>
    </LiComponent>
);

const H1 = styled(Typography)({});
