import React from 'react';
import {Box, Typography, Stack, Grid, Link} from '@mui/material';
import ImgComponent from 'components/ImgComponent';
// import {appsData_} from 'data/AppsData';
import PageFooter from 'components/PageFooter';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AppData } from 'data/AppsData';

export default function GamesList(props: {apps: Array<AppData>}) {
    return (
        <Stack
            direction='column'
            alignContent='center'
            justifyItems='center'
            spacing={2}
            sx={{
                minHeight: '100vh',
                width: '100vw',
            }}
        >
            <PageHeader />
            <PageBody apps={props.apps}/>
            <PageFooter />
        </Stack>
        // </Box>
    );
}

function PageHeader() {
    return (
        <Box
            display='flex'
            justifyContent='center'
            sx={{
                width: '100%',
            }}
        >
            <ImgComponent
                src='/images/akatek_title2.png'
                alt='AKATEK'
                sx={{
                    width: '80%',
                }}
            />
        </Box>
    );
}

function PageBody(props: {apps: Array<AppData>}) {
    return (
        <>
            {props.apps.map((app, index) => (
                <AppCard index={index} app={app}/>
            ))}
        </>
    );
}

// interface AppCardProps {
//     index: number;
// }

const gameIconHeight = 50;
const gameSectionHeight = 100;

function AppCard(props: {app: AppData, index: number}) {
    const app = props.app;

    return (
        <Link href={app.downloadLink} target='_blank' rel='noreferrer'>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                spacing={2}
                sx={{
                    px: 2,
                    height: gameSectionHeight,
                    shadowOpasity: 0,
                    backgroundColor: 'transparent',
                }}
            >
                <ImgComponent src={app.icon} width={gameIconHeight} height={gameIconHeight} alt={app.title} />
                <ImgComponent src={app.titleImage} height={gameIconHeight} alt={app.title} />
                <MoreVertIcon htmlColor='gray' />
            </Stack>
        </Link>
    );
}
