import React from 'react';
import {Container, Box, Grid, Stack, Typography} from '@mui/material';
import PrivacyPolicy, { PrivacyPolicyContents } from './components/PrivacyPolicy';
import {AppData} from './data/AppsData';
import ImgComponent from './components/ImgComponent';
import PageFooter from './components/PageFooter';
import Env from './env';
import { setPageBackground } from 'components/PageBackground';

interface Props {
    app: AppData;
}

const contentsWidth = 800;
const contentsHeight = 600;

export default function PrivacyPolicyPage(props: Props) {

    setPageBackground(props.app);

    if (Env.isMobile) return <PrivacyPolicyPageMobile app={props.app} />;

    return <PrivacyPolicyPageDesktop app={props.app} />;
}

function PrivacyPolicyPageMobile(props: {app: AppData}) {
    return (
        <Stack direction='column' 
            alignItems='center'
            sx={{
                height: '100%',
            }}
        >
            <ImgComponent
                src={props.app.titleImage}
                sx={{
                    mt: 2,
                    width: '80%',
                }}
            />

            <Box
                sx={{
                    p: 2,
                }}
            >
                <PrivacyPolicyContents app={props.app} />
            </Box>
        </Stack>
    );
}

function PrivacyPolicyPageDesktop(props: {app: AppData}) {
    const app = props.app;
    
    return (
        <Box
            sx={{
                padding: 0,
                margin: 0,

                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,

                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center',

                // backgroundRepeat: 'no-repeat',
                // backgroundSize: 'cover',
                // backgroundColor: app.background.color ? app.background.color : undefined,
                // backgroundImage: app.background.mobile ? `url('${app.background.image}')` : undefined,

                // // backgroundImage: "url('" + props.appData.pageBackgroundImage + "')",
                // backgroundAttachment: 'fixed',
                // backgroundClip: 'content-box',
            }}
        >
            <Stack
                direction='column'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                    height: '100%',
                }}
            >
                <ImgComponent
                    src={app.titleImage}
                    sx={{
                        mt: 3,
                        height: 100,
                    }}
                />

                <Box
                    sx={{
                        position: 'absolute',
                        top: 140,
                        bottom: 30,
                        width: contentsWidth,
                        backgroundColor: 'background.paper',
                        borderRadius: 4,
                        boxShadow: 2,
                    }}
                >
                    <PrivacyPolicy app={props.app} />
                </Box>
            </Stack>
            <PageFooter />
        </Box>
    );
}

