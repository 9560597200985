import React from 'react';
import { useSearchParams } from "react-router-dom";

import {Container, Box, Stack, Typography} from '@mui/material';
import {Grid} from '@mui/material';

import {Card, CardActionArea, CardMedia, CardContent} from '@mui/material';

import ImgComponent from './components/ImgComponent';

import Env from './env';
import HomePageMobile from './HomePageMobile';
import { AppData, AppsData } from 'data/AppsData';
import PrivacyPolicyPage from 'PrivacyPolicyPage';
import { appsData } from 'index';


const cardWidth = 300;
const cardHeight = 300;


export default function HomePage(props: {apps: AppsData}) {
    const [searchParams] = useSearchParams();

    let privacy = searchParams.get('privacy');
    if (privacy) {
        let appData = appsData.find((app) => app.tag === privacy);
        if (appData)
            return <PrivacyPolicyPage app={appData}/>
    }


    // React.useEffect(() => {
    //     let privacy = searchParams.get('privacy');


    // }, []);
    
    if (Env.isMobile) return <HomePageMobile apps={props.apps}/>;

    return (
        <Box
            sx={{
                padding: 0,
                margin: 0,

                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                // backgroundRepeat: 'no-repeat',
                // backgroundSize: 'cover',
                // backgroundImage: "url('/images/brick_background_blue2.png')",
                // backgroundAttachment: 'fixed',
                // backgroundClip: 'content-box',
                //opacity: 0.3,
            }}
        >
            <Stack
                direction='column'
                justifyContent='space-between'
                justifyItems='center'
                alignItems='center'
                sx={{
                    height: '100%',
                    width: 800,
                }}
            >
                <ImgComponent src='/images/akatek_title2.png' height={160}/>
                {/* <ImgComponent src='/images/akatek_title2.png' width={200}/> */}

                {/* <PageBody apps={props.apps}/> */}
                {/* <Box>&nbsp;</Box> */}
                {/* <PageFooter /> */}

                <Grid
                    container
                    spacing={2}
                    // sx={{
                    //     maxWidth: 800,
                    // }}
                >
                    {props.apps.map((app, index) => {
                        if (app.hidden)
                            return <React.Fragment key={index}></React.Fragment>;

                        return <Grid item key={index} xs={4} display='flex' justifyContent='center' alignItems='center'>
                            <AppCard index={index} app={app}/>
                        </Grid>;
                    })}
                </Grid>


                <Box flex={1} />

                <Typography
                    fontSize='small'
                    // align='center'
                    sx={{
                        color: 'gray',
                    }}
                >
                    Copyright © 2021-2024 AkaTek LLC. All Rights Reserved.
                </Typography>

            </Stack>
        </Box>
    );
}

// function PageBody(props: {apps: AppsData}) {
//     return (
//         <Grid
//             container
//             spacing={2}
//             sx={{
//                 maxWidth: 800,
//             }}
//         >
//             {props.apps.map((app, index) => {
//                 if (app.hidden)
//                     return <React.Fragment key={index}></React.Fragment>;

//                 return <Grid item key={index} xs={4} display='flex' justifyContent='center' alignItems='center'>
//                     <AppCard index={index} app={app}/>
//                 </Grid>;
//             })}
//         </Grid>
//     );
// }


function AppCard(props: {app: AppData, index: number}) {
    const app = props.app;

    return (
        <Card
            sx={{
                width: cardWidth,
                height: cardWidth,
                //maxWidth: 345,
                // backgroundColor: 'transparent',
                backgroundColor: '#00000088',
                //backgroundColor: '#88888822',
                shadowOpacity: 0,
            }}
        >
            <CardActionArea
                href={app.home}
                target='_blank'
                rel='noreferrer'
                sx={{
                    height: '100%',
                }}
            >
                <CardContent
                    sx={
                        {
                            //px: 2,
                            //flex: '1 0 auto',
                        }
                    }
                >
                    {/* <CardMedia component='img' image={app.cardTitleImage ?? app.titleImage} alt={app.title} sx={{
                        height: '70px',
                    }}/> */}

                    <Stack
                        direction='column'
                        alignContent='center'
                        alignItems='center'
                        justifyContent='space-between'
                        spacing={3}
                        sx={{
                            height: '100%',
                            //p: 2,
                        }}
                    >

                        {/* <ImgComponent src={app.cardTitleImage} height={cardHeight / 4} alt={app.title} /> */}
                        <ImgComponent src={app.cardTitleImage} width='80%'/>


                        {/* Icon  */}
                        {/* <ImgComponent src={app.icon} width={cardWidth / 3} height={cardWidth / 3} alt={app.title} /> */}
                        <ImgComponent src={app.icon}  width='50%'/>

                        {/* <CardMedia
                        component='img'
                        image={app.icon}
                        alt={app.title}
                        width={cardWidth}
                        sx={{
                            width: cardWidth / 3,
                        }}
                    /> */}

                        {/* <Typography variant='body2' align='center'>
                            {app.shortDescription}
                        </Typography> */}
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
