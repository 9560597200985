import React from 'react';

import {Stack, Box, Typography} from '@mui/material';

import device from 'current-device';

import ImgComponent from './ImgComponent';
import {AppleAppStoreButton, GooglePlayStoreButton} from './AppStoreButtons';
import PageFooter from './PageFooter';
import {AppData} from '../data/AppsData';
import AppScreenshotsGalleryMobile from './AppScreenshotsGalleryMobile';
import { pageBackgroundSx } from './PageBackground';

const galleryBoxShadow = 'inset 1px 1px 5px 1px #000000';
const galleryBackgroundColor = '#2f2f2f';
//export const galleryBackgroundColor = 'red';


export default function AppHomePageMobile(props: {app: AppData}) {
    const [currentScreenshotIndex, setCurrentScreenshotIndex] = React.useState(0);

    const app = props.app;

    return (
        <Stack
            direction='column'
            alignItems='center'
            // justifyContent='space-between'
            // spacing={4}
            sx={[pageBackgroundSx(app), {
                // height: '100%',
                // mt: 2,
                // px: 2,
            }]}
        >
            <ImgComponent
                src={app.titleImage}
                alt={app.title}
                sx={{
                    width: 0.9,
                    mt: 3,
                }}
            />

            <ImgComponent
                src={app.icon}
                sx={{
                    width: 120,
                    my: 3,
                }}
            />


            <Typography
                variant='body1'
                align='center'
                sx={{
                    //width: '100%',
                    mt: 2,
                    mx: 2,
                }}
            >
                {app.description}
            </Typography>

            <Stack direction='column' sx={{mt: 2}}>
                {device.android() && <GooglePlayStoreButton href={app.downloadLink} />}
                {device.ios() && <AppleAppStoreButton href={app.downloadLink} />}
            </Stack>

            {/* <Box sx={{mt: 4}}></Box> */}


            {/* Screenshot */}
            <ImgComponent
                src={app.screenshots[currentScreenshotIndex].mobile}
                sx={{
                    my: 4,
                    width: '60%',

                    boxShadow: galleryBoxShadow,
                    background: galleryBackgroundColor,
                    borderRadius: 2,

                    transition: 'opacity 0.8s',
                }}
            />

            {/* <Box sx={{mt: 4}}></Box> */}

            <AppScreenshotsGalleryMobile
                app={app}
                onThumbnailClick={(index) => setCurrentScreenshotIndex(index)}
                sx={{
                    mt: 3,
                    px: 4,
                }}
            />

            {/* <PageBody app={app} /> */}

            {/* <Box
                    sx={{
                        height: 100,
                    }}
                ></Box> */}
            <PageFooter />
        </Stack>
    );
}

