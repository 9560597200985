import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import {GlobalStyles} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';

import '@fontsource/roboto';

import globalStyles, {muiTheme} from './theme';

import HomePage from './HomePage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import AppSupportPage from 'AppSupportPage';
import GamesList from './GamesList';
import AppPage from 'components/AppPage';
//import { appsData_ } from 'data/AppsData';

import appDataYaml from './data/apps_data.yaml';
import { AppData, AppSchemaData, loadAppsData } from 'data/AppsData';


const inputGlobalStyles = <GlobalStyles styles={globalStyles} />;

const container = document.getElementById('root')!;
const root = createRoot(container);

export const appsData = loadAppsData(appDataYaml as Array<AppSchemaData>);

root.render(
    <ThemeProvider theme={muiTheme}>
        {inputGlobalStyles}
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<HomePage apps={appsData}/>} />

                {appsData.map(app => (
                    <React.Fragment key={app.tag}>
                        <Route path={`/apps/${app.tag}`} element={<AppPage app={app}/>} />
                        <Route path={`/apps/${app.tag}/privacy_policy`} element={<PrivacyPolicyPage app={app}/>} />
                        <Route path={`/apps/${app.tag}/privacy`} element={<PrivacyPolicyPage app={app}/>} />
                        <Route path={`/apps/${app.tag}/support`} element={<AppSupportPage app={app}/>} />
                    </React.Fragment>
                ))}

                {/* <Route path='/apps/shw' element={<YaappzLandingPage />} /> */}

                <Route path='/games_list' element={<GamesList apps={appsData}/>} />
                <Route path='/games' element={<GamesList apps={appsData}/>} />
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
);

