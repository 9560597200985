import Env from "env";

import { AppData } from "data/AppsData";
import { SxObject } from "tsui/Mui/SxPropsUtil";

export function pageBackgroundSx(app: AppData): SxObject {
    let image = Env.isMobile ? app.background.mobile : app.background.image;

    return {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: app.background.color ? app.background.color : undefined,
        backgroundImage: image ? `url('${image}')` : undefined,
        backgroundAttachment: 'fixed',
        backgroundClip: 'content-box',
    }
}


export function setPageBackground(app: AppData) {
    //let sx = pageBackgroundSx(app)!;

    let image = Env.isMobile ? app.background.mobile : app.background.image;

    // return {
    //     backgroundRepeat: 'no-repeat',
    //     backgroundSize: 'cover',
    //     backgroundColor: app.background.color ? app.background.color : undefined,
    //     backgroundImage: image ? `url('${image}')` : undefined,
    //     backgroundAttachment: 'fixed',
    //     backgroundClip: 'content-box',
    // }


    document.body.style.backgroundImage = image ? `url('${image}')` : '';
}
